<!-- 班级管理 -->
<template>
  <div class="container">
    <div class="main">
      <div class="header_box flexSb">
        <div class="flexSt">
          <div class="flexSt list">
            <div class="title">年级名称</div>
            <el-select
              filterable
              @change="handleSearch"
              v-model="form.name"
              placeholder="请输入年级名称">
              <el-option
                v-for="(item, index) in teacherlist"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="flexSt list">
            <div class="title">班级名称</div>
            <el-input
              clearable
              v-model="form.classname"
              placeholder="请输入班级名称"></el-input>
          </div>

          <div class="flexSt list list22">
            <div class="title">任课老师</div>
            <el-select
              filterable
              @change="handleSearch"
              v-model="form.teacher"
              placeholder="请选择或搜索老师">
              <el-option
                v-for="(item, index) in teacherlist"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flexSb list">
          <el-button @click="handleSearch" type="primary" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="handleclearSearch" icon="el-icon-refresh"
            >重置</el-button
          >
        </div>
      </div>
      <!-- <div class="add">
      <div class="flexSt">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleEdit('/editTeacher')"
          >新增班级</el-button
        ><el-button icon="el-icon-delete" @click="handleShowDel"
          >删除</el-button
        >
      </div>
    </div> -->

      <div class="table_box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column type="selection" width="25"> </el-table-column>
          <el-table-column
            :index="indexMethod"
            type="index"
            label="序号"
            width="60"
            align="center">
          </el-table-column>

          <el-table-column width="140" label="班级名称" sortable align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">X2201</div>
            </template>
          </el-table-column>

          <el-table-column width="140" label="所属年级" sortable align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">一年级</div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="任课老师" align="center">
            <template slot-scope="scope">
              <div>张老师</div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="学生数" align="center">
            <template slot-scope="scope">
              <div>40</div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="排序值" sortable align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">0</div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" label="备注" align="center">
            <template slot-scope="scope">
              <div>备注备注备注</div>
            </template>
          </el-table-column>

          <el-table-column
            width="100"
            fixed="right"
            label="操作"
            align="center">
            <template slot-scope="scope">
              <div class="flexSb">
                <el-button
                  type="text"
                  @click="handleEdit('/editTeacher?id=' + scope.row.id)"
                  >编辑</el-button
                >
                <el-dropdown @command="handleShowPhysicalAttendance">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">
                      <i class="iconfont icon-ze-todo-list-o"></i>体育课考勤
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <i class="iconfont icon-if-cheer-leader"></i>
                      大课间考勤</el-dropdown-item
                    >
                    <el-dropdown-item command="2"
                      ><i class="iconfont icon-mb-layout"></i
                      >作业管理</el-dropdown-item
                    >

                    <el-dropdown-item command="3"
                      ><i class="iconfont icon-if-license"></i
                      >体艺2+1</el-dropdown-item
                    >
                    <el-dropdown-item command="4"
                      ><i class="iconfont icon-mb-paste"></i
                      >体质健康档案</el-dropdown-item
                    ><el-dropdown-item
                      ><i class="iconfont icon-ze-chart-trending-o"></i
                      >学生核心素养</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="flexSe elpla">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
      <AttendanceDialog ref="AttendanceDialog"></AttendanceDialog>
      <recessDialog ref="recessDialog"></recessDialog>
    </div>
  </div>
</template>
<script>
import AttendanceDialog from "./components/AttendanceDialog.vue";
import recessDialog from "./components/recessDialog.vue";
export default {
  components: {
    AttendanceDialog,
    recessDialog,
  },
  data() {
    return {
      schoollist: [],
      form: {
        school: "",
        name: "",
        teacher: "",
        classname: "",
      },
      teacherlist: [],
      tableData: [12],
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 1,
      },
      AttendanceDialog: false,
    };
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  mounted() {
    // this.$refs.AttendanceDialog.show();
  },
  methods: {
    handleShowPhysicalAttendance(command) {
      if (command == 1) {
        this.$refs.AttendanceDialog.show();
      } else if (5) {
        this.$refs.recessDialog.show();
      }
    },
    handleclearSearch() {
      this.form = {
        school: "",
        name: "",
        teacher: "",
        classname: "",
      };
    },
    handleSearch() {},
    handleShowDel() {},
    handleSizeChange() {},
    handleCurrentChange() {},
    handleEdit() {},
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleSelectionChange() {},
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.container {
  /deep/.firstCell {
    .cell {
      overflow: unset !important;
    }
  }
  /deep/.el-dropdown {
    color: #409eff;
  }
  .elpla {
    margin-top: 30px;
  }
  // ::-webkit-scrollbar {
  //   width: 10px !important;
  //   height: 10px !important;
  //   background-color: #ccc;
  // }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    // background-color: #ccc !important;
  }
  ::-webkit-scrollbar-track {
    // background-color: #bbbbbb;
  }
  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  .add {
    margin: 17px 0;
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
  }
  .header_box {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-bottom: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .list {
      margin-bottom: 10px;
    }
    .list2 {
      /deep/.el-cascader {
        width: 147px !important;
        margin-right: 10px;
      }
    }
    /deep/.title {
      color: rgb(78, 89, 105);
      font-size: 14px;
      margin-right: 8px;
      // min-width: 60px;
    }
    .sex {
      /deep/.el-form-item__label {
        width: 47px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 60px !important;
      }
      /deep/.el-select {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        width: 127px !important;
      }
    }
    .sex2 {
      /deep/.el-form-item__label {
        width: 80px !important;
      }
      /deep/.el-form-item__content {
        width: 167px !important;
      }
      /deep/.el-select {
        width: 147px !important;
        height: 32px !important;
      }
    }
    .sex3 {
      /deep/.el-select,
      /deep/.el-input__inner,
      /deep/.el-input {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        // margin-left: 40px !important;
      }
    }
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
    /deep/.el-input,
    /deep/.el-input__inner {
      height: 32px !important;
      width: 147px;
    }
    /deep/.el-input {
      margin-right: 15px;
    }
    .list22 {
      /deep/.el-input,
      /deep/.el-input__inner {
        height: 32px !important;
        width: 160px;
      }
    }

    /deep/.el-input__icon {
      line-height: 32px !important;
    }

    /deep/.el-form-item__content {
      width: 171px !important;
    }
  }
}
</style>
