<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="form_box">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="flexSb">
            <el-form-item label="上课日期" prop="date">
              <el-date-picker
                v-model="ruleForm.date"
                type="date"
                placeholder="请选择上课日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="课堂" prop="class">
              <el-select
                filterable
                clearable
                v-model="ruleForm.class"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in classlist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">
                    <!-- 节数：第一节 时间：08:00-08:45 教师：张老师 -->
                    {{ item.label }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flexSb">
            <el-form-item label="上课时间" prop="startTime">
              <el-time-select v-model="ruleForm.startTime" disabled>
              </el-time-select>
            </el-form-item>
            <el-form-item label="下课时间" prop="endTime">
              <el-time-select v-model="ruleForm.endTime" disabled>
              </el-time-select>
            </el-form-item>
          </div>
          <el-form-item label="备注" prop="tip">
            <el-input v-model="ruleForm.tip" placeholder="请输入备注">
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="tab_box">
        <div class="flexSt btnbox">
          <el-button icon="el-icon-check" type="primary" plain
            >全出勤</el-button
          >
          <el-button icon="el-icon-close" type="danger" plain>全缺勤</el-button>
        </div>

        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName"
        >
          <el-table-column fixed type="selection" width="25"> </el-table-column>
          <el-table-column
            :index="indexMethod"
            type="index"
            label="序号"
            width="60"
            align="center"
            fixed
          >
          </el-table-column>

          <el-table-column
            fixed
            width="80"
            label="班号"
            sortable
            align="center"
          >
            <template slot-scope="scope">
              <div style="padding-right: 16px">X2201</div>
            </template>
          </el-table-column>

          <el-table-column
            fixed
            width="100"
            label="姓名"
            sortable
            align="center"
          >
            <template slot-scope="scope">
              <div style="padding-right: 16px">张张是啊</div>
            </template>
          </el-table-column>
          <el-table-column width="140" label="出勤情况" align="center">
            <template slot-scope="scope">
              <el-radio-group v-model="radio1">
                <el-radio-button label="出勤"></el-radio-button>
                <el-radio-button label="请假"></el-radio-button>
                <el-radio-button label="缺勤"></el-radio-button>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column width="80" label="性别" sortable align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">男</div>
            </template>
          </el-table-column>
          <el-table-column width="100" label="监护人" align="center">
            <template slot-scope="scope">
              <div>张三吧</div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="监护人电话" align="center">
            <template slot-scope="scope">
              <div>13584584584</div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" label="备注" align="center">
            <template slot-scope="scope">
              <div>
                <el-input placeholder="请输入"></el-input>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="open">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var _this = this;
    return {
      ruleForm: {
        date: "",
        class: "",
        startTime: "",
        endTime: "",
        tip: "",
      },
      rules: {
        date: { required: true, message: "请选择上课日期", trigger: "blur" },
        class: { required: true, message: "请选择课堂", trigger: "blur" },
        startTime: {
          required: true,
          message: "上课时间不能为空",
          trigger: "blur",
        },
        endTime: {
          required: true,
          message: "下课时间不能为空",
          trigger: "blur",
        },
      },

      pickerOptions: {
        disabledDate(date) {
          date.setHours(8);
          if (date.getTime() > Date.now()) {
            return true;
          }
          for (let i = 0; i < _this.disabledDates.length; i++) {
            console.log(_this.disabledDates[i]);
            if (date.getTime() === new Date(_this.disabledDates[i]).getTime()) {
              return true;
            }
          }
          return false;
        },
      },
      disabledDates: ["2024-01-10", "2024-01-09", "2024-01-01"],
      dialogVisible: false,
      title: "",
      tableData: [1, 1, 1, 1, 1, 1, 1, 1],
      classlist: [
        {
          value: "1",
          label: "节数：第一节 时间：08:00-08:45 教师：张老师",
        },
        {
          value: "2",
          label: "节数：第一节 时间：08:00-08:45 教师：1老师",
        },
        {
          value: "3",
          label: "节数：第一节 时间：08:00-08:45 教师：2老师",
        },
        {
          value: "4",
          label: "节数：第一节 时间：08:00-08:45 教师：3老师",
        },
      ],
      radio1: "出勤",
    };
  },
  methods: {
    open() {
      this.$confirm("确认提交考勤数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          this.dialogVisible = false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交",
          });
        });
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleSelectionChange() {},
    show() {
      this.title = "学生体育课出勤:X2301(望月湖一小)";
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  width: 50%;
}
/deep/.el-select {
  width: 100%;
}
/deep/.el-select-dropdown {
  .el-select-dropdown__item {
    color: pink !important;
  }
}

/deep/.el-radio-button__inner {
  padding: 5px;
  border: 1px solid #1684fc;
  border-left: 0;
}
/deep/.el-radio-button:first-child {
  .el-radio-button__inner {
    border-left: 1px solid #1684fc;
  }
}
/deep/ .el-input,
/deep/.el-input__inner {
  width: 100% !important;
}

/deep/.firstCell {
  .cell {
    overflow: unset !important;
  }
}
/deep/.el-dropdown {
  color: #409eff;
}
.elpla {
  margin-top: 30px;
}
// ::-webkit-scrollbar {
//   width: 10px !important;
//   height: 10px !important;
//   background-color: #ccc;
// }
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  // background-color: #ccc !important;
}
::-webkit-scrollbar-track {
  // background-color: #bbbbbb;
}
/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
/deep/.el-dialog__body {
  padding: 20px 0;
}
.form_box {
  padding-right: 50px;
}
.tab_box {
  border-top: 1px solid rgba(228, 237, 252, 1);
  border-bottom: 1px solid rgba(228, 237, 252, 1);
  box-sizing: border-box;
  padding: 15px 20px;
}
.btnbox {
  /deep/.el-button {
    padding: 0 10px;
    height: 30px !important;
    line-height: 30px;
  }
  margin-bottom: 16px;
}
</style>
